/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import styled from "@emotion/styled"
import { EducationSection } from "components/EducationSection"
import { InfoBlock } from "components/InfoBlock"
import { LanguageToggle } from "components/LanguageToggle"
import { ProjectsSection } from "components/ProjectsSection"
import { useData } from "useData"
import { FC } from "react"
import { SkillsSection } from "SkillsSection"
import "twin.macro"
import tw from "twin.macro"
import { PDFDownloadButton } from "components/PDFDownloadButton"
import { useLanguage } from "language"

export const Title = tw.h1`text-4xl font-bold font-serif`

export const TopLevelHeading = tw.h2`text-xl font-bold font-serif pb-1`

export const SubHeading = tw.h3`text-lg font-bold font-serif`

export const Container: FC = ({ children, ...props }) => (
  <div
    css={css`
      ${tw`bg-white flex justify-center min-h-screen`};
      @media (min-width: calc(21cm)) {
        ${tw`bg-gray-100`}
      }
    `}
    {...props}
  >
    <div
      css={css`
        width: 100%;
        max-width: 21cm;
        @media (min-width: calc(22cm)) {
          margin: 2vh 0;
        }
      `}
    >
      {children}
    </div>
  </div>
)

export const Sheet = styled.div`
  ${tw`w-full bg-white`};
  @media screen {
    padding: 7vw;
  }
  @media screen and (min-width: calc(21cm)) {
    ${tw`shadow`}
  }
  @media (min-width: calc(21cm + 2cm + 2cm)) {
    padding: 2cm;
  }
  @media print {
    padding: 0.75cm;
  }
`

export const Resume: FC = () => {
  const {
    meta: { name },
  } = useData()
  const { lang } = useLanguage()

  return (
    <div tw="relative">
      <a
        css={css`
          position: absolute;
          bottom: 1cm;
          right: 1.1cm;
          ${tw`text-gray-400 hidden print:block`}
        `}
        href="https://resume.locksten.com"
      >
        {lang({ en: "view in browser", lt: "žiūrėti naršyklėje" })}
      </a>
      <Container>
        <div tw="print:hidden flex justify-between sm:justify-end space-x-4 py-2 px-2">
          <PDFDownloadButton />
          <LanguageToggle />
        </div>
        <Sheet tw="grid gap-6">
          <div tw="flex flex-wrap justify-between">
            <Title tw="mr-8 leading-none">{name}</Title>
            <InfoBlock />
          </div>
          <EducationSection tw="-mt-4 print:-mt-8" />
          <SkillsSection />
          <ProjectsSection />
        </Sheet>
      </Container>
    </div>
  )
}
