/** @jsx jsx */
import { jsx } from "@emotion/core"
import { SubHeading, TopLevelHeading } from "components/pages/Resume"
import { useData } from "useData"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { LanguageObject, useLanguage } from "language"
import { FC, Fragment } from "react"
import "twin.macro"

// fixed(width: 160) {
// fixed(width: 190) {
export const projectImageQuery = graphql`
  query PojectImageQuery {
    allFile(filter: { relativeDirectory: { eq: "project_images" } }) {
      edges {
        node {
          name
          childImageSharp {
            fixed(width: 190) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`

const ProjectImage: FC<{ name: string }> = ({ name, ...props }) => {
  const nodes: { name: string; childImageSharp: any }[] = useStaticQuery(
    projectImageQuery
  ).allFile.edges.map(edge => edge.node)

  const filename = name.toLowerCase().replace(/ /g, "-")
  const node = nodes.find(node => node.name === filename)
  if (node === undefined) console.error(`could not find the file '${filename}'`)

  return (
    <Fragment>
      {node && (
        <Img
          fixed={node.childImageSharp.fixed}
          alt={`Image of ${name}`}
          {...props}
        />
      )}
    </Fragment>
  )
}

const Project: FC<{
  project: { name: string; url: string; description: LanguageObject }
}> = ({ project: { name, url, description } }) => {
  const { lang } = useLanguage()

  const Name: FC = () => (
    <SubHeading>
      <a href={url} target="_blank">
        {name}
      </a>
    </SubHeading>
  )

  const Image: FC = ({ ...props }) => (
    <a href={url} target="_blank" {...props}>
      <ProjectImage name={name} tw="flex-shrink-0 mr-5 rounded-md mt-1" />
    </a>
  )

  return (
    <div tw="flex items-start">
      <Image tw="hidden sm:block print:block" />
      <div tw="flex-col">
        <Name />
        <Image tw="sm:hidden print:hidden" />
        <p>{lang(description)}</p>
      </div>
    </div>
  )
}

export const ProjectsSection: FC = () => {
  const { lang } = useLanguage()
  const { projects } = useData()

  return (
    <section>
      <TopLevelHeading>
        {lang({ en: "Projects", lt: "Projektai" })}
      </TopLevelHeading>
      <ul tw="space-y-4 mt-1">
        {projects.map(project => (
          <li key={project.name}>
            <Project project={project} />
          </li>
        ))}
      </ul>
    </section>
  )
}
