/** @jsx jsx */
import { css } from "@emotion/core"
import tw from "twin.macro"

export const globalStyle = css`
  body {
    ${tw`text-gray-800`};
    ${tw`font-sans`}
  }
  button {
    font-weight: inherit;
  }
`

// // default tailwind breakpoints
// export const bp = {
//   sm: `@media (min-width: 640px)`,
//   md: `@media (min-width: 768px)`,
//   lg: `@media (min-width: 1024px)`,
//   xl: `@media (min-width: 1280px)`,
// }
