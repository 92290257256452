/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { FC } from "react"
import { FaGithub } from "react-icons/fa"
import { MdLocationOn, MdMail, MdPhone } from "react-icons/md"

export type IconName = keyof typeof Icons

export const Icons = {
  location: <MdLocationOn />,
  mail: <MdMail />,
  phone: <MdPhone />,
  github: (
    <FaGithub
      css={css`
        margin-bottom: 1px;
      `}
    />
  ),
}

export const Icon: FC<{ icon: IconName }> = ({ icon, ...props }) => (
  <div tw="flex justify-center items-center" {...props}>
    {Icons[icon]}
  </div>
)
