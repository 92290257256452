/** @jsx jsx */
import { jsx } from "@emotion/core"
import { FC, ReactNode } from "react"
import "twin.macro"
import { useData } from "useData"
import { IconName, Icon } from "components/Icon"
import { googleMapsSearch, stringFrom } from "utils"
import { useLanguage } from "language"

export const InfoBlock: FC = () => {
  const { lang } = useLanguage()
  const {
    meta: { email, github, location, phone },
  } = useData()

  const Row: FC<{ icon: IconName; val: ReactNode }> = ({
    icon,
    val,
    ...props
  }) => (
    <tr {...props}>
      <td tw="pr-1">
        <Icon icon={icon} />
      </td>
      <td>{val}</td>
    </tr>
  )

  return (
    <table>
      <tbody>
        <Row
          icon={"location"}
          val={
            <a href={googleMapsSearch(lang(location))} target="_blank">
              {lang(location)}
            </a>
          }
        />
        <Row icon={"mail"} val={<a href={`mailto:${email}`}>{email}</a>} />
        <Row
          tw={"hidden print:table-row"}
          icon={"phone"}
          val={<a href={`tel:${phone}`}>{phone}</a>}
        />
        <Row
          icon={"github"}
          val={
            <a href={github} target="_blank">
              {stringFrom(github, "github")}
            </a>
          }
        />
      </tbody>
    </table>
  )
}
