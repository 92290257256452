export const data = {
  meta: {
    name: "Aivaras Taroza",
    email: "tar.aivaras@gmail.com",
    phone: "+370 (633) 89 408",
    github: "https://github.com/locksten",
    location: { lt: "Kaunas, Lietuva", en: "Kaunas, Lithuania" },
  },
  education: {
    en:
      "Last year Bachelor of Informatics student at Kaunas University of Technology.",
    lt:
      "Paskutinių metų Informatikos Bakalauro studentas Kauno Technologijų Universitete.",
  },
  skills: {
    main: [
      "Typescript",
      "Javascript",
      "React",
      "Node.js",
      "GraphQL",
      "HTML",
      "CSS",
    ],
    familiar: [
      "SQL",
      "Kotlin",
      "Go",
      "Java",
      "C#",
      "Bash",
      "Docker",
      "C",
      "Haskell",
    ],
  },
  projects: [
    {
      name: "Twitit",
      url: "https://github.com/locksten/twitit",
      description: {
        en:
          "A twitter-like web-app web app built using React, Node, PostgreSQL, and GraphQL,\
           and deployed using Docker and Google Cloud Compute.",
        lt:
          "Twitter stiliaus svetainė, sukurta naudojant React, Node, PostgreSQL, ir GraphQL,\
           ir įdiegta naudojant Docker and Google Cloud Compute.",
      },
    },
    {
      name: "Daystich",
      url: "https://github.com/locksten/daystich",
      description: {
        en:
          "A time tracking web app based on a hierarchy of activities and tags.\
           Written in TypeScript using React and Redux.",
        lt:
          "Internetinė laiko sekimo programa, pagrįsta veiklų ir žymų hierarchija.\
           Parašyta naudojant Typescript, React ir Redux.",
      },
    },
    {
      name: "TailwindCSS Padded Radius",
      url: "https://github.com/locksten/tailwindcss-padded-radius",
      description: {
        en:
          "A TailwindCSS plugin that adds variants for creating matching nested border radii.",
        lt:
          "TailwindCSS plėtinys, pridedantis variantus kraštų spindulių suderinimui.",
      },
    },
    {
      name: "Endeavor",
      url: "https://github.com/locksten/endeavor",
      description: {
        en:
          "A gamified todo / habit tracker app for Android\
           written in Kotlin using Jetpack Compose with a GraphQL server written in Typescript.",
        lt:
          "Sužaidiminta užduočių / įpročių sekimo Android programėlė\
           parašyta Kotlin naudojant Jetpack Compose su GraphQL serveriu parašytu Typescript.",
      },
    },
    {
      name: "Lusp",
      url: "https://github.com/locksten/lusp",
      description: {
        en:
          "An interpreter for a Scheme-like language written in Haskell.\
          Features dynamic scoping, macros, scheme's numerical tower, and a REPL.",
        lt:
          "Vykdyklė Scheme stiliaus kalbai parašyta Haskell kalboje.\
           Palaiko dinamines sritis, makroprogramas, Scheme skaičių bokštą, ir REPL.",
      },
    },
  ],
}
