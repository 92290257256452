/** @jsx jsx */
import { jsx } from "@emotion/core"
import { createContext, FC, useState, useContext, ReactNode } from "react"

export const languages = ["en", "lt"] as const

type Language = typeof languages[number]

export type LanguageObject = { [K in Language]: ReactNode }

const LanguageContext = createContext<{
  language: Language
  setLanguage: (_: Language) => void
  lang: (s: string | LanguageObject) => string
}>(undefined)

export const useLanguage = () => {
  return useContext(LanguageContext)
}

export const useLanguageProvider = (initialState: Language = "en") => {
  const [language, setLanguage] = useState(initialState)

  const lang = (s: string | LanguageObject) =>
    (typeof s === "string" ? s : s[language]) as string

  const LanguageProvider: FC<{}> = ({ children }) => {
    return (
      <LanguageContext.Provider
        value={{
          language,
          setLanguage,
          lang,
        }}
      >
        {children}
      </LanguageContext.Provider>
    )
  }

  return {
    LanguageProvider,
    language,
    setLanguage,
    lang,
  }
}
