import "tailwindcss/dist/base.min.css"
import { Resume } from "components/pages/Resume"
import React, { FC } from "react"
import { useLanguageProvider } from "language"
import { Global } from "@emotion/core"
import { globalStyle } from "styling/Themes"
import { Helmet } from "react-helmet"
import { useData } from "useData"

const Index: FC = () => {
  const { LanguageProvider } = useLanguageProvider()
  const {
    meta: { name },
  } = useData()

  return (
    <LanguageProvider>
      <Helmet>
        <title>{name}</title>
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap"
          rel="stylesheet"
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Merriweather:wght@700&display=swap"
          rel="stylesheet"
        ></link>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        ></link>
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        ></link>
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        ></link>
        <link rel="manifest" href="/site.webmanifest"></link>
      </Helmet>
      <Global styles={globalStyle} />
      <Resume />
    </LanguageProvider>
  )
}

export default Index
