/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { FC } from "react"
import "twin.macro"
import { useLanguage, languages } from "language"
import tw from "twin.macro"

export const LanguageToggle: FC = () => {
  const { language: currentLanguage, setLanguage } = useLanguage()
  return (
    <div tw="-mx-2 divide-x divide-gray-500 leading-tight">
      {languages.map(language => (
        <button
          key={language}
          css={css`
            ${tw`px-2 uppercase`};
            ${language === currentLanguage
              ? tw`text-gray-700`
              : tw`text-gray-500 hover:text-gray-600`};
          `}
          disabled={language === currentLanguage}
          onClick={() => setLanguage(language)}
        >
          {language}
        </button>
      ))}
    </div>
  )
}
