/** @jsx jsx */
import { jsx } from "@emotion/core"
import { FC } from "react"
import "twin.macro"
import { useLanguage } from "language"
import { useData } from "useData"
import { TopLevelHeading } from "components/pages/Resume"

export const EducationSection: FC = ({ ...props }) => {
  const { lang } = useLanguage()
  const { education } = useData()
  return (
    <section {...props}>
      <TopLevelHeading>
        {lang({ en: "Education", lt: "Išsilavinimas" })}
      </TopLevelHeading>
      <p>{lang(education)}</p>
    </section>
  )
}
