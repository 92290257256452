/** @jsx jsx */
import { jsx } from "@emotion/core"
import { SubHeading, TopLevelHeading } from "components/pages/Resume"
import { useData } from "useData"
import { LanguageObject, useLanguage } from "language"
import { FC } from "react"
import "twin.macro"

export const SkillsSection: FC = () => {
  const { lang } = useLanguage()
  const { skills } = useData()

  const List: FC<{
    skills: string[]
  }> = ({ skills }) => (
    <ol tw="flex flex-wrap space-x-2">
      {skills.map((skill, idx) => (
        <li key={idx}>{skill}</li>
      ))}
    </ol>
  )

  const Row: FC<{
    title: LanguageObject
    skills: string[]
  }> = ({ title, skills }) => (
    <tr tw="align-middle">
      <td tw="pr-4">
        <SubHeading>{lang(title)}</SubHeading>
      </td>
      <td>
        <List skills={skills} />
      </td>
    </tr>
  )

  return (
    <section>
      <TopLevelHeading>
        {lang({
          en: "Languages / Technologies",
          lt: "Kalbos / Technologijos",
        })}
      </TopLevelHeading>
      <table>
        <tbody>
          <Row title={{ en: "Main", lt: "Pagrindinės" }} skills={skills.main} />
          <Row
            title={{ en: "Familiar with", lt: "Pažystamos" }}
            skills={skills.familiar}
          />
        </tbody>
      </table>
    </section>
  )
}
