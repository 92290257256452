/** @jsx jsx */
import { jsx } from "@emotion/core"
import { useLanguage } from "language"
import { FC } from "react"
import "twin.macro"

const pdf = {
  en: "/pdfs/en/Aivaras Taroza CV.pdf",
  lt: "/pdfs/lt/Aivaras Taroza CV.pdf",
}

export const PDFDownloadButton: FC = () => {
  const { lang } = useLanguage()
  return (
    <a
      href={lang(pdf)}
      target="_blank"
      tw="text-gray-500 hover:text-gray-600 active:text-gray-700 leading-tight"
    >
      {lang({ en: "PDF Download", lt: "PDF atsisiųsti" })}
    </a>
  )
}
